import { useEffect, useState } from "react";
import ReturnIcon from "../../assets/images/return.svg";
import Header from "../../components/header";
import { Form } from "react-bootstrap";
import "./profil.scss"; // Add your custom styles
import Services from "../../services/global.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Autocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const Informations = () => {
  const [error, setError]=useState('')
  const [adresse, setAdresse] = useState("");
  const [LatLng, setLatLng] = useState(null);
  const navigate=useNavigate()
  // State to manage edit mode for each field
  const [editMode, setEditMode] = useState({
    prenom: false,
    nom: false,
    email: false,
    telephone: false,
    adresse: false,
    date_naissance: false,
  });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    console.log(`${year}-${month}-${day}`)
    return `${day}-${year}-${month}`;
  };
  // State to store form data
  const [formData, setFormData] = useState({
    prenom: "",
    nom: "",
    email: "",
    telephone: "",
    adresse: "",
    date_naissance: null,
  });
  const handleChangeAddress = (address, type) => {
    if (type === "adresse") {
      setAdresse(address);
      setError("");
      setLatLng(null)
    }
  };

  const handleSelect = async (address, type) => {
    if (type === "adresse") {
      setAdresse(address);
    } 

    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      const postalCode = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      const street = addressComponents.find(component =>
        component.types.includes('route')
      );

      if (postalCode && street) {
        const latLng = await getLatLng(results[0]);
        if (latLng) {
          if (type === "adresse") {
            setLatLng(latLng);
          }
        } else {
          console.error("Error: Unable to get latitude and longitude");
        }
      } else {
        const errorMessage = 'Adresse sélectionnée incomplète. Code postal ou rue manquant.';
        if (type === "adresse") {
          setError(errorMessage);
          setLatLng(null);
        } 
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
    types: ['address']
  };
  const getMyProfil = () => {
    const idUser = localStorage.getItem('userData');
    console.log('userData', idUser);
    Services.getOne(idUser, 'users')
      .then((res) => {
        const userData = res?.data?.data;
  
        // Set formData with the user data
        setFormData({
          prenom: userData?.prenom || '',       // Use API value or default empty
          nom: userData?.nom || '',
          email: userData?.email || '',
          telephone: userData?.telephone || '',
           adresse: userData?.adresse || '',
          date_naissance: userData?.date_naissance ? formatDate(userData.date_naissance) : '', // Ensure date is formatted
        });
        setAdresse(userData.adresse)
        setLatLng({lat:userData.adresse_lat,lng:userData.adresse_long})
        console.log(userData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  useEffect(()=>{getMyProfil()},[])
  // State to store original form data before editing
  const [originalData, setOriginalData] = useState(formData);

  // Handle changes to input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Toggle edit mode for specific fields
  const toggleEditMode = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  // Save changes for specific fields
  const handleSave = (field) => {
    console.log(`Saved ${field}:`, formData[field]);
    // if(formData[field]!==""){
    //   toast.error("Tous les champs sont obligatoires", {
    //     position: 'top-right',
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     draggable: true,
    //     progress: undefined,
    //    // theme: 'light', // Choose theme (light/dark)
    //     className: 'custom-toast-error'

    //   });
    // }
    if (field === "telephone") {
      const regex = /^(?:\+33|0033)\d{9}$/;
      if (!regex.test(formData.telephone)) {
        toast.error("Numéro de téléphone invalide. Il doit commencer par +33 ou 0033 et être suivi de 9 chiffres.", {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
        //setError("Numéro de téléphone invalide. Il doit commencer par +33 ou 0033 et être suivi de 9 chiffres.");
        return;
      }
    }
    let data={
      [field]:formData[field]
    }
    if (field === "adresse") {
      data={...data,adresse_lat:LatLng.lat,adresse_long:LatLng.lng,adresse:adresse}
    }
  
   
    console.log(data)
    Services.create(data,'update_user')
    .then((res)=>{
      console.log(res)
      toast.success('Votre opération a réussi !', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast'

      });

    })
    .catch((err)=>{
      console.log(err)
      toast.error(err?.response?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'

      });
    })
    toggleEditMode(field);
  };

  // Cancel editing and revert changes
  const handleCancel = (field) => {
    setFormData(originalData); // Revert to original data
    toggleEditMode(field); // Exit edit mode
  };
  const hasChanged = (field) => {
   // return formData[field] !== originalData[field];
  //  console.log('formData[field] !== originalData[field]',formData[field] !== originalData[field])
  //  console.log('formData[field].trim().length > 0',formData[field].trim().length > 0)
    return formData[field] !== originalData[field] && formData[field].trim().length > 0;
  };
  return (
    <>
      <div className="desktop">
        <Header connected={true}/>
      </div>

      <div className="bg-atchoum-profil">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex align-items-center mb-100">
             <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
            Informations personnelles
          </h1>
        </div>

        <div className="p-16 mt-32 col-lg-4 mx-auto">
          <div className="row">
            {/* Prénom Field */}
            <div className="col-lg-12 col-xs-12 py-16 border-b">
              <div>
                {editMode.prenom ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <p className="current-text">Prénom</p>
                      <button
                        className="cta"
                        onClick={() => handleCancel("prenom")}
                      >
                        Annuler
                      </button>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleChange}
                         className="p-16 floating-input"
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className="btn-primary-s w-100"
                        onClick={() => handleSave("prenom")}
                        disabled={!hasChanged("prenom")}
                      >
                        Enregistrer
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="exergue my-0">Prénom</p>
                      <span className="current-text mt-8">
                        {formData.prenom}
                      </span>
                    </div>
                    <button
                      className="cta"
                      onClick={() => {
                        setOriginalData(formData); // Save original data before editing
                        toggleEditMode("prenom");
                      }}
                    >
                      Modifier
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* Nom Field */}
            <div className="col-lg-12 col-xs-12 py-16 border-b">
              <div>
                {editMode.nom ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <p className="current-text">Nom</p>
                      <button
                        className="cta"
                        onClick={() => handleCancel("nom")}
                      >
                        Annuler
                      </button>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="nom"
                        value={formData.nom}
                        onChange={handleChange}
                         className="p-16 floating-input"
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className="btn-primary-s w-100"
                        onClick={() => handleSave("nom")}
                        disabled={!hasChanged("nom")}

                      >
                        Enregistrer
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="exergue my-0">Nom</p>
                      <span className="current-text mt-8">{formData.nom}</span>
                    </div>
                    <button
                      className="cta"
                      onClick={() => {
                        setOriginalData(formData); // Save original data before editing
                        toggleEditMode("nom");
                      }}
                    >
                      Modifier
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* Email Field */}
            <div className="col-lg-12 col-xs-12 py-16 border-b">
              <div>
                {editMode.email ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <p className="current-text">Adresse e-mail</p>
                      <button
                        className="cta"
                        onClick={() => handleCancel("email")}
                      >
                        Annuler
                      </button>
                    </div>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="p-16 floating-input"
                    />
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className="btn-primary-s w-100"
                        onClick={() => handleSave("email")}
                        disabled={!hasChanged("email")}

                      >
                        Enregistrer
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="exergue my-0">Adresse email</p>
                      <span className="current-text mt-8">
                        {formData.email}
                      </span>
                    </div>
                    <button
                      className="cta"
                      onClick={() => {
                        setOriginalData(formData); // Save original data before editing
                        toggleEditMode("email");
                      }}
                    >
                      Modifier
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Téléphone Field */}
            <div className="col-lg-12 col-xs-12 py-16 border-b">
              <div>
                {editMode.telephone ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <p className="current-text">Téléphone</p>
                      <button
                        className="cta"
                        onClick={() => handleCancel("telephone")}
                      >
                        Annuler
                      </button>
                    </div>
                    <input
                      type="text"
                      name="telephone"
                      value={formData.telephone}
                      onChange={handleChange}
                       className="p-16 floating-input"
                    />
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className="btn-primary-s w-100"
                        onClick={() => handleSave("telephone")}
                        disabled={!hasChanged("telephone")}

                      >
                        Enregistrer
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="exergue my-0">Téléphone</p>
                      <span className="current-text mt-8">
                        {formData.telephone}
                      </span>
                    </div>
                    <button
                      className="cta"
                      onClick={() => {
                        setOriginalData(formData); // Save original data before editing
                        toggleEditMode("telephone");
                      }}
                    >
                      Modifier
                    </button>
                  </div>
                )}
              </div>
            </div>
             {/* naissance Field */}
             <div className="col-lg-12 col-xs-12 py-16 border-b">
              <div>
                {editMode.date_naissance ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <p className="current-text">Date de naissance</p>
                      <button
                        className="cta"
                        onClick={() => handleCancel("date_naissance")}
                      >
                        Annuler
                      </button>
                    </div>
                    <Form.Control
                      type="date"
                      name="date_naissance"
                      value={formData.date_naissance}
                      onChange={handleChange}
                      className="form-control"
                    />
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className="btn-primary-s w-100"
                        onClick={() => handleSave("date_naissance")}
                        disabled={!hasChanged("date_naissance")}

                      >
                        Enregistrer
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="exergue my-0">Date de naissance</p>
                      <span className="current-text mt-8">
                        {formData.date_naissance}
                      </span>
                    </div>
                    <button
                      className="cta"
                      onClick={() => {
                        setOriginalData(formData); // Save original data before editing
                        toggleEditMode("date_naissance");
                      }}
                    >
                      Modifier
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* Adresse Field */}
            <div className="col-lg-12 col-xs-12 py-16 border-b">
              <div>
                {editMode.adresse ? (
                  <>
                    <div className="d-flex justify-content-between">
                      <p className="current-text">Adresse</p>
                      <button
                        className="cta"
                        onClick={() => handleCancel("adresse")}
                      >
                        Annuler
                      </button>
                    </div>
                    <div>
                    <Autocomplete
                    value={formData.adresse || adresse}
                    searchOptions={searchOptions}
                    onChange={(addr) => handleChangeAddress(addr, "adresse")}
                    onSelect={(addr) => handleSelect(addr, "adresse")}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="floating-input-container ">
                        <input
                          {...getInputProps({
                            placeholder: "",
                           
                            className: ` floating-input  ${error ? "danger-input" : ""}`
                          })}
                          id="floatingAdr"
                           name="adresse"
                        />
                        <label htmlFor="floatingAdr" className="floating-label">Adresse</label>
                        <div className={suggestions.length > 0 ? "container-autocomplete" : ""}>
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active ? "#41B6E6" : "#fff",
                            };
                            return (
                              <div
                                className="mapItem"
                                {...getSuggestionItemProps(suggestion, { style })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Autocomplete>
                      {/* <input
                        type="text"
                        name="adresse"
                        value={formData.adresse}
                        onChange={handleChange}
                         className="p-16 floating-input"
                      /> */}
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <button
                        className="btn-primary-s w-100"
                        onClick={() => handleSave("adresse")}
                       // disabled={!hasChanged("adresse")}
                      >
                        Enregistrer
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="exergue my-0">Adresse</p>
                      <span className="current-text mt-8">
                        {formData.adresse}
                      </span>
                    </div>
                    <button
                      className="cta"
                      onClick={() => {
                        setOriginalData(formData); // Save original data before editing
                        toggleEditMode("adresse");
                      }}
                    >
                      Modifier
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Informations;
