import Autocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Covoiturage = () => {
  const [depart, setDepart] = useState("");
  const [destination, setDestination] = useState("");
  const [departLatLng, setDepartLatLng] = useState(null);
  const [destinationLatLng, setDestinationLatLng] = useState(null);
  const [error, setError] = useState("");
  const [errorDestination, setErrorDestination] = useState("");
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false); // State to track if Google Maps is loaded

  const handleChangeAddress = (address, type) => {
    if (type === "destination") {
      setDestination(address);
      setErrorDestination("");
    } else {
      setDepart(address);
      setError("");
    }
  };
  useEffect(() => {
    if (!window.google) { // Only load if Google Maps is not already loaded
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true); // Set state when loaded
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true); // Google Maps is already loaded
    }
  }, []);
  const handleSelect = async (address, type) => {
    if (type === "destination") {
      setDestination(address);
    } else {
      setDepart(address);
    }

    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      const postalCode = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      const street = addressComponents.find(component =>
        component.types.includes('route')
      );

      if (postalCode && street) {
        const latLng = await getLatLng(results[0]);
        if (latLng) {
          if (type === "destination") {
            setDestinationLatLng(latLng);
          } else {
            setDepartLatLng(latLng);
          }
        } else {
          console.error("Error: Unable to get latitude and longitude");
        }
      } else {
        const errorMessage = 'Adresse sélectionnée incomplète. Code postal ou rue manquant.';
        if (type === "destination") {
          setErrorDestination(errorMessage);
          setDestinationLatLng(null);
        } else {
          setError(errorMessage);
          setDepartLatLng(null);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
    types: ['address']
  };

  const validationSchema = Yup.object({
    departDateTime: Yup.string().required("Date et heure de départ requises"),
    arrivalDateTime: Yup.string().required("Date et heure d'arrivée requises"),
    seats: Yup.number().required("Nombre de places requis").min(1, "Au moins 1 place"),
    comments: Yup.string(),
  });

  return (
    <Formik
      initialValues={{
        departDateTime: '',
        arrivalDateTime: '',
        seats: '',
        comments: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log('Form submitted:', values);
        // Handle form submission
      }}
    >
      {({ setFieldValue }) => (
        <Form className="mb-3 p-16">
          {/* Autocomplete for Depart */}
          <div className="form-floating">
          {isGMapsLoaded && <Autocomplete
              value={depart}
              searchOptions={searchOptions}
              onChange={(addr) => handleChangeAddress(addr, "depart")}
              onSelect={(addr) => handleSelect(addr, "depart")}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="floating-input-container">
                  <input
                    {...getInputProps({
                      placeholder: "",
                      className: `floating-input input-home radius-top ${error ? "danger-input" : ""}`
                    })}
                    id="floatingAdr"
                  />
                  <label htmlFor="floatingAdr" className="floating-label home-label">Adresse de départ</label>
                  <div className={suggestions.length > 0 ? "container-autocomplete" : ""}>
                    {loading ? <div>Loading...</div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#41B6E6" : "#fff",
                      };
                      return (
                        <div
                          className="mapItem"
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Autocomplete>
}
          </div>

          {/* Autocomplete for Destination */}
          <div className="form-floating">
          {isGMapsLoaded &&    <Autocomplete
              value={destination}
              searchOptions={searchOptions}
              onChange={(addr) => handleChangeAddress(addr, "destination")}
              onSelect={(addr) => handleSelect(addr, "destination")}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="floating-input-container mb-3">
                  <input
                    {...getInputProps({
                      placeholder: "",
                      className: `floating-input input-home radius-bottom ${errorDestination ? "danger-input" : ""}`
                    })}
                    id="floatingDestination"
                  />
                  <label htmlFor="floatingDestination" className="floating-label home-label">Adresse de destination</label>
                  <div className={suggestions.length > 0 ? "container-autocomplete" : ""}>
                    {loading ? <div>Loading...</div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#41B6E6" : "#fff",
                      };
                      return (
                        <div
                          className="mapItem"
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Autocomplete>
}
            <div className="text-danger-custom">{errorDestination}</div>
            <div className="text-danger-custom">{error}</div>
          </div>

          {/* Departure Date and Time */}
          <div className="form-floating">
            <Field type="button" name="departDateTime" value="Date et heure de départ" className="input-home date-home  floating-input" />
            {/* <label htmlFor="departDateTime" className="floating-label">Date et heure de départ</label> */}
            
          </div>

         
          <ErrorMessage name="departDateTime" component="div" className="text-danger-custom" />
          {/* Number of Seats */}
          <div className="floating-input-container mt-8">
            <Field type="number" name="seats"
             className="floating-input input-home" placeholder="" />
            <label htmlFor="seats" className="floating-label home-label">Nombre de places </label>
            <ErrorMessage name="seats" component="div" className="text-danger-custom" />
          </div>

        

          <button type="submit" className="btn-primary-s mt-16 p-16 w-100">Rechercher un covoiturage</button>
          <button type="submit" className="btn-secondary-s mt-16 p-16 w-100">Proposer un covoiturage</button>
        </Form>
      )}
    </Formik>
  );
};

export default Covoiturage;
