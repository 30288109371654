import { useEffect, useState } from "react";
import moment from "moment";
import ReturnIcon from "../../assets/images/return.svg";
import Delete from "../../assets/images/Delete.svg";
import Check from "../../assets/images/check-orange.svg";
import EyeOpened from "../../assets/images/open-eye.svg";
import Header from "../../components/header";
import Services from "../../services/global.service";
import ShowDocModal from "../../components/showDoc";
import { toast } from "react-toastify";

const MyDocs = () => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [docs, setDocs] = useState({
    justificatif: [],
    permis: [],
    carteGrise: [],
    assurance: [],
  });

  // Handle image change for each document (allow multiple files)
  const handleImageChange = (e, docType) => {
    const files = Array.from(e.target.files);
    if(files.length>2){
      toast.error('Vous ne pouvez sélectionner que deux fichiers merci !', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'
  
      });
      return;
    }
    if (files.length) {
      setDocs((prevDocs) => ({
        ...prevDocs,
        [docType]: [...prevDocs[docType], ...files], // Append new files to the existing ones
      }));
    }
  };

  const getDocs = () => {
    const idUser = localStorage.getItem("userData");
    Services.getOne(idUser, "users")
      .then((res) => {
        const documents = res?.data?.data?.documents || [];
        console.log("documents", documents);
        // Initialize the new docs state
        const updatedDocs = {
          justificatif: [],
          permis: [],
          carteGrise: [],
          assurance: [],
        };

        // Map documents to the corresponding doc types
        documents.forEach((doc) => {
          console.log("doc", doc);
          switch (doc.name) {
            case "piece_identite":
              console.log("la");
              updatedDocs.justificatif = [...updatedDocs.justificatif, doc];
              break;
            case "permis_de_conduire":
              updatedDocs.permis = [...updatedDocs.permis, doc];
              break;
            case "carte_grise":
              updatedDocs.carteGrise = [...updatedDocs.carteGrise, doc];
              break;
            case "attestation_assurance":
              updatedDocs.assurance = [...updatedDocs.assurance, doc];
              break;
            default:
              break;
          }
        });
        console.log("updatedDocs", updatedDocs);
        // Set the updated documents state
        setDocs(updatedDocs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDocs();
  }, []);

  // Handle form submission (send multiple files)
  const handleSubmit = () => {
    const formData = new FormData();
    console.log(docs);
    // Append multiple files for each document type
    if (!docs.justificatif[0]?.updated_at) {
      docs.justificatif.forEach((file) =>
        formData.append("piece_identite[]", file)
      );
    }
    if (!docs.assurance[0]?.updated_at) {
      docs.assurance.forEach((file) =>
        formData.append("attestation_assurance[]", file)
      );
    }
    if (!docs.carteGrise[0]?.updated_at) {
      docs.carteGrise.forEach((file) => formData.append("carte_grise[]", file));
    }
    if (!docs.permis[0]?.updated_at) {
      docs.permis.forEach((file) =>
        formData.append("permis_de_conduire[]", file)
      );
    }

    Services.create(formData, "update_user")
      .then((res) => {
        console.log(res);
        toast.success('Votre opération a réussi !', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          onClose: ()=>getDocs(),
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast'
  
        });
        getDocs()
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
      });
  };

  const showDoc = (url) => {
    setUrl(url);
    setOpen(!open);
  };
  const onDelete = (type) => {
    console.log(type);
    Services.create({ name: type }, "documents")
      .then((res) => {
        console.log(res);
        getDocs()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {open && url && <ShowDocModal open={open} setOpen={setOpen} url={url} />}
      <div className="desktop">
        <Header connected={true}/>
      </div>

      <div className="bg-atchoum-profil">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex align-items-center mb-100">
            <a href="javascript:history.go(-1)">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </a>
            Mes documents
          </h1>
        </div>
        <div className="px-16 mt-32 container-fluid-lg">
          <p className="subtitle bleuM500 text-start">
            Si vous ajoutez vos documents, vous aurez plus de chances de trouver
            des passagers.
          </p>

          {/* Justificatif d'identité */}
          <div className="card-atch p-16 ">
            <div className="d-flex align-items-center justify-content-center mb-8">
              <div className="col">
                <p className="exergue text-center m-0  bleuM500">
                  Justificatif d’identité
                </p>
              </div>
              {/* Show delete button only if there's a file from the server */}
              {docs.justificatif.length > 0 &&
                docs.justificatif[0]?.updated_at && (
                  <div className="text-end">
                    <button
                      className="cta"
                      onClick={() => onDelete("piece_identite")}
                    >
                      <img src={Delete} alt="delete icon" />
                    </button>
                  </div>
                )}
            </div>

            {docs.justificatif.length  ? (
              <div className="text-center">
                <>
                  { docs.justificatif[0]?.updated_at &&<span className="date-file">
                    <img src={Check} className="mr-8" alt="check icon" />
                    Ajouté le{" "}
                    {new Date(
                      docs.justificatif[0]?.updated_at
                    ).toLocaleDateString("fr-FR")}
                  </span>
                  }
                </>

                {docs.justificatif.map((file, index) => (
                  <div key={index}>
                    <p className="legend text-center mb-0 mt-8">
                      {file.name || file.name}
                      {/* Show eye icon only for files from the server */}
                      {file?.updated_at && (
                        <button
                          className="cta"
                          onClick={() => {
                            showDoc(file.full_uri);
                          }}
                        >
                          <img
                            src={EyeOpened}
                            alt="show file icon"
                            className="ml-8"
                          />
                        </button>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <button
                  className="btn-secondary-s p-16 w-100"
                  onClick={() =>
                    document.getElementById("justificatifInput").click()
                  }
                >
                  Ajouter
                </button>
                <input
                  type="file"
                  id="justificatifInput"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageChange(e, "justificatif")}
                  style={{ display: "none" }}
                />
              </>
            )}
          </div>

          {/* Permis de conduire */}
          <div className="card-atch mt-8">
          <div className="d-flex align-items-center justify-content-center mb-8">
          <div className="col">
            <p className="exergue text-center  m-0 bleuM500">Permis de conduire</p>
            </div>
            {/* Show delete button only if there's a file from the server */}
            {docs.permis.length > 0 && docs.permis[0]?.updated_at && (
              <div className="text-end">
                <button
                  className="cta"
                  onClick={() => onDelete("permis_de_conduire")}
                >
                  <img src={Delete} alt="delete icon" />
                </button>
              </div>
            )}
            </div>

            {docs.permis.length ? (
              <div className="text-center">
                <>
                  {docs.permis[0]?.updated_at && <span className="date-file">
                    <img src={Check} className="mr-8" alt="check icon" />
                    Ajouté le{" "}
                    {new Date(docs.permis[0]?.updated_at).toLocaleDateString(
                      "fr-FR"
                    )}
                  </span>
              }
                </>
                {docs.permis.map((file, index) => (
                  <div key={index}>
                    <p className="legend text-center mb-0 mt-8">
                      {file.name || file.name}
                      {/* Check if the file is an existing document from the server */}
                      {file?.updated_at && (
                        <>
                          <button
                            className="cta"
                            onClick={() => {
                              showDoc(file.full_uri);
                            }}
                          >
                            <img
                              src={EyeOpened}
                              alt="show file icon"
                              className="ml-8"
                            />
                          </button>
                        </>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <button
                  className="btn-secondary-s p-16 w-100"
                  onClick={() => document.getElementById("permisInput").click()}
                >
                  Ajouter
                </button>
                <input
                  type="file"
                  id="permisInput"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageChange(e, "permis")}
                  style={{ display: "none" }}
                />
              </>
            )}
          </div>

          {/* Carte Grise */}
          <div className="card-atch mt-8">
          <div className="d-flex align-items-center justify-content-center mb-8">
          <div className="col">
            <p className="exergue text-center m-0 bleuM500">Carte grise</p>
            </div>
            {/* Show delete button only if there's a file */}
            {docs.carteGrise.length > 0 && docs.carteGrise[0]?.updated_at && (
              <div className="text-end">
                <button className="cta" onClick={() => onDelete("carte_grise")}>
                  <img src={Delete} alt="delete icon" />
                </button>
              </div>
            )}
            </div>
            {docs.carteGrise.length  ? (
              <div className="text-center">
               {docs.carteGrise[0]?.updated_at &&  <span className="date-file">
                  <img src={Check} className="mr-8" alt="check icon" />
                  Ajouté le{" "}
                  {new Date(docs.carteGrise[0]?.updated_at).toLocaleDateString(
                    "fr-FR"
                  )}
                </span>}
                {docs.carteGrise.map((file, index) => (
                  <div key={index}>
                    <p className="legend text-center mb-0 mt-8">
                      {file.name || file.name}
                      {/* Check if the file is an existing document from the server */}
                      {file?.updated_at && (
                        <>
                          <button
                            className="cta"
                            onClick={() => {
                              showDoc(file.full_uri);
                            }}
                          >
                            <img
                              src={EyeOpened}
                              alt="show file icon"
                              className="ml-8"
                            />
                          </button>
                        </>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <button
                  className="btn-secondary-s p-16 w-100"
                  onClick={() =>
                    document.getElementById("carteGriseInput").click()
                  }
                >
                  Ajouter
                </button>
                <input
                  type="file"
                  id="carteGriseInput"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageChange(e, "carteGrise")}
                  style={{ display: "none" }}
                />
              </>
            )}
          </div>

          {/* Attestation d’assurance */}
          <div className="card-atch mt-8 mb-xs-100">
          <div className="d-flex align-items-center justify-content-center mb-8">
          <div className="col">
            <p className="exergue m-0 text-center bleuM500">
              Attestation d’assurance
            </p>
            </div>
            {/* Show delete button only if there's a file */}
            {docs.assurance.length > 0 && docs.assurance[0]?.updated_at && (
              <div className="text-end">
                <button
                  className="cta"
                  onClick={() => onDelete("attestation_assurance")}
                >
                  <img src={Delete} alt="delete icon" />
                </button>
              </div>
            )}
            </div>
            {docs.assurance.length  ? (
              <div className="text-center ">
               {docs.assurance[0]?.updated_at && <span className="date-file">
                  Ajouté le{" "}
                  {new Date(docs.assurance[0]?.updated_at).toLocaleDateString(
                    "fr-FR"
                  )}
                </span>}
                {docs.assurance.map((file, index) => (
                  <div key={index}>
                    <p className="legend text-center mb-0 mt-8">
                      {file.name || file.name}
                      {/* Check if the file is an existing document from the server */}
                      {file?.updated_at && (
                        <>
                          <button
                            className="cta"
                            onClick={() => {
                              showDoc(file.full_uri);
                            }}
                          >
                            <img
                              src={EyeOpened}
                              alt="show file icon"
                              className="ml-8"
                            />
                          </button>
                        </>
                      )}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <button
                  className="btn-secondary-s p-16 w-100"
                  onClick={() =>
                    document.getElementById("assuranceInput").click()
                  }
                >
                  Ajouter
                </button>
                <input
                  type="file"
                  id="assuranceInput"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageChange(e, "assurance")}
                  style={{ display: "none" }}
                />
              </>
            )}
          </div>
        </div>
        <div className="bottom-white-block">
          <button
            className="btn-primary-s p-16 w-sm-100"
            onClick={handleSubmit}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </>
  );
};

export default MyDocs;
