import Rec1 from '../assets/images/rec1.svg';
import Rec2 from '../assets/images/rec2.svg';
import Rec3 from '../assets/images/rec3.svg';
import Rec4 from '../assets/images/rec4.svg';
import Rec5 from '../assets/images/rec5.svg';
import Rec6 from '../assets/images/rec6.svg';
import Rec7 from '../assets/images/rec7.svg';

const Recompense=()=>{
  const logos = [
    { id: 1, src: Rec1, alt: 'Logo 1' },
    { id: 2, src: Rec2, alt: 'Logo 2' },
    { id: 3, src: Rec3, alt: 'Logo 3' },
    { id: 4, src: Rec4, alt: 'Logo 4' },
    { id: 5, src: Rec5, alt: 'Logo 5' },
    { id: 6, src: Rec6, alt: 'Logo 6' },
    { id: 7, src: Rec7, alt: 'Logo 7' },
  ];
  
      return (
        <div className="container mt-42 logo-pyramid">
            <h2 className='text-center'>Nos récompenses</h2>
        <div className="d-flex flex-row justify-content-between ">
          {logos.slice(0, 3).map((logo) => (
            <div className="col-md-4 col-xs-4">
              <img key={logo.id} src={logo.src} alt={logo.alt} className="logo mr-8" /> 
             </div>
          ))}
        </div>
        <div className=" d-flex flex-row justify-content-between ">
          {logos.slice(3, 7).map((logo) => (
          <div className="col-md-4 col-xs-3">
               <img key={logo.id} src={logo.src} alt={logo.alt} className="logo mr-8" />
        </div>
          ))}
        </div>
      </div>
      );
}
export default Recompense;