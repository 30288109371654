import { ErrorMessage, Formik } from "formik";
import ReturnIcon from "../../assets/images/return.svg";
import Header from "../../components/header";
import { Form } from "react-bootstrap";
import Services from "../../services/global.service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Apropos = () => {
  const [user,setUser]=useState()
  const getMyProfil=()=>{
    const idUser=localStorage.getItem('userData')
    console.log('userData',idUser)
    Services.getOne(idUser,'users')
    .then((res)=>{
      console.log(res)
      setUser(res?.data?.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  useEffect(()=>{getMyProfil()},[])
  const initialValues = {
    presentation: user?.description,
  };
  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    console.log('Form data:', values);

    // Simulate async operation
    Services.create({ description: values.presentation },'update_user')
      .then((res) => {
        console.log(res);
        toast.success('Votre opération a réussi !', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast'
  
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <div className="desktop">
        <Header connected={false} />
      </div>

      <div className="step2">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex align-items-center mb-100">
            <a href="javascript:history.go(-1)">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </a>
            Devenir conducteur solidaire
          </h1>
        </div>
        <div className="mt-16 col-lg-4 mx-auto">
            <h2 className="px-16 bleuM500">A propos de vous</h2>
          <p className="px-16 mt-8 subtitle bleuM500 text-start">
            Ecrivez une courte présentation si vous avez envie de nous parler de vous !
          </p>
          {user && 
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ errors, touched, values, handleSubmit, handleChange, isSubmitting }) => (
              // Use Formik's native form element instead of react-bootstrap's Form
              <form onSubmit={handleSubmit} className="mt-24 ">
                <div className="  px-16 ">
                  <div className="floating-input-container">
                    <textarea
                      name="presentation"
                      className={`floating-input ${touched.presentation && errors.presentation ? 'is-invalid' : ''}`}
                      id="presentation"
                      placeholder=" " // important for floating effect
                      value={values.presentation}
                      onChange={handleChange}
                    />
                    <label htmlFor="presentation" className="floating-label">
                      Votre présentation
                    </label>
                  </div>
                </div>

                <ErrorMessage name="presentation" component="div" className="invalid-feedback" />
                <div className="bottom-white-block">
                  <button type="submit" className="btn-primary-s p-16 w-sm-100" disabled={isSubmitting}>
                    Suivant
                  </button>
                </div>
              </form>
            )}
          </Formik>
          }
          
          <p className="legend bleu400 px-16 ">
            Ce texte sera public sur votre profil, il pourra être modifié à tout moment
          </p>
        </div>
      </div>
    </>
  );
};

export default Apropos;
