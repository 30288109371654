import { useNavigate } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import NOImage from "../../assets/images/no-image.svg";
import Tears from "../../assets/images/tearsRight.svg";
import Pen from "../../assets/images/pen.svg";
import Completed from "../../assets/images/completed.svg";
import "../../assets/styles/bulletin.scss";
import { Field } from "formik";
import { useState } from "react";
import Services from "../../services/global.service";
const Photo = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const handleImageChange = (e) => {
    console.log('here')
    const file = e.target.files[0];
    const formData=new FormData()
    formData.append('photo_profil',file)
    Services.create(formData,'update_user')
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Le résultat de la lecture du fichier
      };
      reader.readAsDataURL(file); // Lire l'image comme une URL de données
    } else {
      setImage(null); // Réinitialise si aucune image n'est sélectionnée
    }
  };
  return (
    <>
      <div className="bg-grey">
        <div className="stepOne ">
          <div className="container-fluid-lg p-16">
            <h1 className="text-white  d-flex">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Devenir conducteur solidaire
            </h1>
          </div>
        </div>
        <div className="p-16">
          <h2 className="m-0 bleuM500 container-fluid-lg ">
        Ajoutez une photo de vous
        </h2>
        <p className="current-text mt-8">
        Une photo permet aux passagers de mieux vous reconnaitre.
        </p>
        <div className="card-atch mt-8">
        <div className="image-upload pt-16">
        <div className=" position-relative icon-container-profil">
          <div className="image-preview-solidaire  "  >
            {image ? (
              <img src={image} alt="Aperçu" className="image-preview__image" />
            ) : (
              <img src={NOImage} alt="NOImage" className="w-auto" />
            )}
          </div>
          <img src={Tears} alt="Icon" className="floating-icon-solidaire" />
          {/* <div className="circle-pen">
            <img
              src={Pen}
              alt="Edit Icon"
              className="edit-icon"
              // Simule un clic sur l'input file
            />
          </div> */}
        </div>
        <button className="btn-secondary-s p-16" onClick={() => document.getElementById("fileInput").click()}>Ajouter une photo de profil </button>
        {/* Champ input de fichier caché */}
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }} // Masquer l'input file
        />
       
        </div>
        </div>
        </div>
        
      </div>
    </>
  );
};
export default Photo;
