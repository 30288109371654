import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ItemCarousel from "./itemCarousel";
import ImageOne from "../assets/images/imageccm1.svg";
import ImageTwo from "../assets/images/imageccm2.svg";
import ImageThree from "../assets/images/imageccm3.svg";
const Ccm = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
    },
  };
  const data = [
    {
      id: 1,
      image: ImageOne,
      text: "Je fais ma demande de trajet par le centre d'appel* ou par le site Acthoum",
    },
    {
      id: 2,
      image: ImageTwo,
      text: "Je réserve simplement mon trajet avec un conducteur Atchoum",
    },
    {
      id: 3,
      image: ImageThree,
      text: "Je paye par carte bleue ou en ticket mobilité",
    },
  ];
  return (
    <div className="container">
      <h2>Comment ça marche ?</h2>
      <Carousel partialVisible={true} responsive={responsive} className="mt-16">
        {data.map((item, key) => {
         
          return <ItemCarousel data={item} key={key}  itemKey={key}   />;
        })}
      </Carousel>
      <p className="text-after mt-8">*pour les communes abonnées</p>
    </div>
  );
};
export default Ccm;
