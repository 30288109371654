import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Autocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

// Import assets
import "../../assets/styles/login.scss";
import Alert from "../../assets/images/danger.svg";
import ReturnIcon from "../../assets/images/return.svg";
import { useNavigate } from "react-router-dom";
import ModalRetraite from "../../components/modalRetraite";
import Services from "../../services/global.service";

const Register = () => {
  const navigate = useNavigate();
  const initialValues = {
    civilite: "",
    prenom: "",
    nom: "",
    dateNaissance: null, // Date is initialized as null
    numeroTel: "",
    adresse: "", // Added to store address
    conducteurSolidaire: false, // Default to unchecked
    adhereAssociation: false, // Default to unchecked
    accepteCguCgv: false, // Default to unchecked (must be true to submit)
    // Additional field for age > 64
    retraite: "", // Initialize additional field
  };
  
  const [adresse, setAdresse] = useState("");
  const [LatLng, setLatLng] = useState(null);
  const [error, setError] = useState("");
  const [modalRetraite, setModalRetraite] = useState(false);
  const [selectedRetraite, setSelectedRetraite] = useState("");
  // Helper function to calculate age
  const calculateAge = (birthDate) => {
    if (!birthDate) return null;
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const validationSchema = Yup.object({
    civilite: Yup.string().required("Civilité est requis"),

    prenom: Yup.string().required("Prénom est requis"),

    nom: Yup.string().required("Nom est requis"),

    adresse: Yup.string().required("Adresse est requis"),

    dateNaissance: Yup.date()
      .required("Date de naissance est requis")
      .nullable(),

    numeroTel: Yup.string()
      .matches(
        /^(?:\+33|0033)\d{9}$/,
        "Numéro de téléphone invalide. Il doit commencer par +33 ou 0033 et être suivi de 9 chiffres."
      )
      .required("Numéro de téléphone est requis"),

    //adresse: Yup.string().required("Adresse est requise"),

    conducteurSolidaire: Yup.boolean(),

    adhereAssociation: Yup.boolean(),

    accepteCguCgv: Yup.boolean().oneOf(
      [true],
      "Vous devez accepter les CGU et CGV"
    ),

    // Conditional validation for retraite
    // retraite: Yup.string().when('dateNaissance', (dateNaissance, schema) => {
    //   const age = calculateAge(dateNaissance);
    //   return age > 64 ? schema.required("Ce champ est requis pour les utilisateurs de plus de 64 ans") : schema;
    // }),
  });

  const handleChangeAddress = (address, type,setFieldValue) => {
    if (type === "adresse") {
      setAdresse(address);
      setError("");
      setLatLng(null)
      setFieldValue("adresse", address);
      setError("");
      setFieldValue("adresseLat", null);
      setFieldValue("adresseLong", null);
    }
  };

  const handleSelect = async (address, type,setFieldValue) => {
    if (type === "adresse") {
      setAdresse(address);
      setFieldValue("adresse", address);
      setError("");
      setFieldValue("adresseLat", null);
      setFieldValue("adresseLong", null);
    } 

    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      const postalCode = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      const street = addressComponents.find(component =>
        component.types.includes('route')
      );

      if (postalCode && street) {
        const latLng = await getLatLng(results[0]);
        if (latLng) {
          if (type === "adresse") {
            setLatLng(latLng);
          }
        } else {
          console.error("Error: Unable to get latitude and longitude");
        }
      } else {
        const errorMessage = 'Adresse sélectionnée incomplète. Code postal ou rue manquant.';
        if (type === "adresse") {
          setError(errorMessage);
          setLatLng(null);
        } 
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ['fr'] },
    types: ['address']
  };

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form data:", LatLng);
    if(!LatLng){
      console.log('here')
      setError('Adresse est obligatoire');
    }
   const data={
      "civilite": values.civilite,
      "nom": values.nom,
      "prenom": values.prenom,
      "date_naissance": values.dateNaissance,
      "telephone": values.numeroTel,
      "adresse": adresse,
      "adresse_long" :LatLng.lat,
      "adresse_lat" :LatLng.lng,
      "caisse_retraite":selectedRetraite,
      "adhesion_mobilite_village":values.adhereAssociation,
      "conducteur_solidaire": values.conducteurSolidaire,
   }
   console.log('token', localStorage.getItem('token'))
   Services.updatewithoutID(data,'update_user')
   .then((res)=>{
    console.log('res', res.data)
    localStorage.setItem('userData', res.data.data.id)
    localStorage.setItem('userData', res.data.data.id)
    window.location.href ='/'
   })
   .catch((err)=>{
    console.log(err)
   })
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };
 

  return (
    <div className="bg-grey">
      <div className="lastStepBg ">
        <div className="container-fluid-lg p-16">
          <h1 className="text-white d-flex mt-8">
             <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
            Inscription
          </h1>
        </div>
      </div>
      <h2 className="col-lg-4 mx-auto px-16">Vos informations personnelles</h2>
      {modalRetraite && (
        <ModalRetraite
          open={modalRetraite}
          setOpen={setModalRetraite}
          selectedRetraite={selectedRetraite}
          setSelectedRetraite={setSelectedRetraite}
        />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, isValid, dirty,setFieldValue }) => {
          const age = calculateAge(values.dateNaissance);
          return (
            <Form className="mt-8 ">
              <div className="p-16 col-lg-4 mx-auto">
                {/* Civilité Field as Radio Buttons */}
                <div className="form-group text-start">
                  <label className=" exergue bleu600">Civilité</label>
                  <div role="group" aria-labelledby="civilite-radio-group mt-16" className="mt-16">
                    <div>
                      <label className="d-flex align-items-center current-text bleuM500">
                        <Field
                          type="radio"
                          name="civilite"
                          value="femme"
                          id="femme"
                        />
                        <span className="custom-radio"></span> Femme
                      </label>
                    </div>
                    <div className="mt-8">
                      <label className="d-flex align-items-center current-text bleuM500">
                        <Field
                          type="radio"
                          name="civilite"
                          value="homme"
                          id="homme"
                        />
                        <span className="custom-radio"></span> Homme
                      </label>
                    </div>
                    <div className="mt-8">
                      <label className="d-flex align-items-center current-text bleuM500">
                        <Field
                          type="radio"
                          name="civilite"
                          value="ne se prononce pas"
                          id="neSePrononcePas"
                        />
                        <span className="custom-radio"></span> Ne se prononce pas
                      </label>
                    </div>
                  </div>
                  {touched.civilite && errors.civilite ? (
                    <div className="invalid-feedback">{errors.civilite}</div>
                  ) : null}
                </div>

                {/* Prénom Field */}
                <div className="floating-input-container mt-16">
                  <Field
                    type="text"
                    name="prenom"
                    className={`floating-input radius-top ${
                      touched.prenom && errors.prenom ? "is-invalid" : ""
                    }`}
                    id="prenom"
                    placeholder=""
                  />
                  <label htmlFor="prenom" className="floating-label">
                    Prénom
                  </label>
                </div>

                {/* Nom Field */}
                <div className="floating-input-container">
                  <Field
                    type="text"
                    name="nom"
                    className={`floating-input radius-bottom ${
                      touched.nom && errors.nom ? "is-invalid" : ""
                    }`}
                    id="nom"
                    placeholder=""
                  />
                  <label htmlFor="nom" className="floating-label">
                    Nom
                  </label>
                </div>
                <ErrorMessage name="prenom">
                  {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                  {/* Display error message below input */}
                <ErrorMessage name="nom" >
                {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                {/* Date de naissance Field */}
                <div className="floating-input-container mt-8">
                  <Field
                    type="date"
                    name="dateNaissance"
                    className={`floating-input radius-24 form-control ${
                      touched.dateNaissance && errors.dateNaissance
                        ? "is-invalid"
                        : ""
                    }`}
                    id="dateNaissance"
                    
                  />
                  <label htmlFor="dateNaissance" className="floating-label">
                    Date de naissance
                  </label>
                   {/* Display error message below input */}
                    <ErrorMessage
                      name="dateNaissance" 
                    >

                  {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                </div>
    
                {/* Numéro de téléphone Field */}
                <div className="floating-input-container mt-8">
                  <Field
                    type="tel"
                    name="numeroTel"
                    className={`floating-input ${
                      touched.numeroTel && errors.numeroTel ? "is-invalid" : ""
                    }`}
                    id="numeroTel"
                    placeholder=""
                  />
                  <label htmlFor="numeroTel" className="floating-label">
                    Numéro de téléphone
                  </label>
                  {/* Display error message below input */}
                   <ErrorMessage name="numeroTel" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                </div>

                {/* Adresse Field with Autocomplete */}
                <div className="floating-input-container mt-8">
                  <Autocomplete
                    value={adresse}
                    searchOptions={searchOptions}
                    onChange={(address) => handleChangeAddress(address,'adresse', setFieldValue)}
                    onSelect={(address) => handleSelect(address,'adresse',  setFieldValue)}
                    // onChange={(addr) => {
                    //   handleChangeAddress(addr, "adresse");
                    // }}
                    // onSelect={(addr) => handleSelect(addr, "adresse")}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="floating-input-container ">
                        <input
                          {...getInputProps({
                            placeholder: "",
                           
                            className: ` floating-input  ${error ? "danger-input" : ""}`
                          })}
                          className={`floating-input ${
                            touched.adresse && errors.adresse ? "is-invalid" : ""
                          }`}
                          id="floatingAdr"
                          name="adresse"
                        />
                        <label htmlFor="floatingAdr" className="floating-label">Adresse</label>
                        <div className={suggestions.length > 0 ? "container-autocomplete" : ""}>
                          {loading ? <div>Loading...</div> : null}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active ? "#41B6E6" : "#fff",
                            };
                            return (
                              <div
                                className="mapItem"
                                {...getSuggestionItemProps(suggestion, { style })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Autocomplete>
                  <ErrorMessage name="adresse">
                  {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
                  {/* <div className="error-message">{error}</div> */}
                </div>

                  {/* Conditional Additional Input for Age > 64 */}
            {age !== null && age > 60 && (
                <div className="floating-input-container mt-16">
                  <Field
                    type="button"
                    value={`${selectedRetraite ==="" ? 'Votre caisse de retraite' : selectedRetraite }`}
                    className=" pt-16 floating-input text-start bg-white deroulante"
                    onClick={() => setModalRetraite(true)} // Open modal on click
                  />
                  <ErrorMessage name="retraite"  >
                  {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                </div>
              )}

                {/* Checkbox: Conducteur Solidaire */}
                <div className="form-group text-start mt-16">
                  <label className="d-flex align-items-center current-text">
                    <Field
                      type="checkbox"
                      name="conducteurSolidaire"
                      id="conducteurSolidaire"
                    />
                    <span className="custom-checkbox"></span> Je souhaite être
                    conducteur solidaire
                  </label>
                </div>

                {/* Checkbox: Adhère Association */}
                <div className="form-group text-start mt-8">
                  <label className="d-flex align-items-center current-text">
                    <Field
                      type="checkbox"
                      name="adhereAssociation"
                      id="adhereAssociation"
                    />
                    <span className="custom-checkbox"></span> 
                    <span>Je souhaite adhérer
                    <a href="" className="cta-bleu current-text"> à l'association atchoum mobilité villages</a>
                    </span>
                  </label>
                </div>

                {/* Checkbox: Accepte CGU CGV (this must be true) */}
                <div className="form-group text-start mb-80 mt-8">
                  <label className="d-flex align-items-center current-text">
                    <Field
                      type="checkbox"
                      name="accepteCguCgv"
                      id="accepteCguCgv"
                    />
                    <span className="custom-checkbox"></span>
                    <span> J’accepte les 
                    <a href="" className="cta-bleu current-text"> CGU</a> et 
                    <a href="" className="cta-bleu current-text"> politique de confidentialité</a> 
                    </span>
                  </label>

                  {touched.accepteCguCgv && errors.accepteCguCgv ? (
                    <div className="invalid-feedback">{errors.accepteCguCgv}</div>
                  ) : null}
                </div>
              </div>
              {/* Submit Button */}
              <div className="bottom-white-block">
                <button type="submit" 
               disabled={!(isValid && dirty)} 
                className="btn-primary-s p-16 w-sm-100">
                  Terminer
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Register;
