import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import IconClose from '../assets/images/iconClose.svg'
const Contact = ({ open, setOpen }) => {
  // Validation schema for the form
  const validationSchema = Yup.object({
    prenom: Yup.string().required("Le prénom est requis"),
    nom: Yup.string().required("Le nom est requis"),
    collectivite: Yup.string().required("Le nom de la collectivité est requis"),
    email: Yup.string()
      .email("Adresse e-mail invalide")
      .required("L'adresse e-mail est requise"),
    phone: Yup.string().required("Le numéro de téléphone est requis"),
    message: Yup.string().required("Le message est requis"),
  });

  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          setOpen(false);
        }}
      >
        <Modal.Header className="border-none d-flex justify-content-between">
          <Modal.Title className=" ">
            <h1 className="bleu600">Contacter-nous</h1> 
            </Modal.Title>
            <button className="cta" onClick={()=>{setOpen(false)}}><img src={IconClose} alt="icon close"/></button>
         
        </Modal.Header>

        <Modal.Body>
          <Formik
            initialValues={{
              prenom: "",
              nom: "",
              collectivite: "",
              email: "",
              phone: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              // Handle form submission here
              console.log(values);
              setSubmitting(false);
              setOpen(false); // Close modal after submission
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group floating-input-container">
                  <Field
                    type="text"
                    name="prenom"
                    className="floating-input radius-top"
                    placeholder=""
                  />
                  <label htmlFor="prenom" className="floating-label">
                    Prénom
                  </label>
                </div>
               

                <div className="form-group floating-input-container">
                  <Field
                    type="text"
                    name="nom"
                    className="floating-input radius-bottom"
                    placeholder=""
                  />
                  <label htmlFor="nom" className="floating-label">
                    Nom
                  </label>
                </div>
                <ErrorMessage
                  name="nom"
                  component="div"
                  className="text-danger"
                />
               <ErrorMessage
                  name="prenom"
                  component="div"
                  className="text-danger"
                />
                <div className="form-group floating-input-container  mt-16">
                  <Field
                    type="text"
                    name="collectivite"
                    className="floating-input"
                    placeholder=""
                  />
                  <label htmlFor="collectivite" className="floating-label">
                    Nom de la collectivité
                  </label>
                </div>
                <ErrorMessage
                  name="collectivite"
                  component="div"
                  className="text-danger"
                />

                <div className="form-group floating-input-container mt-16">
                  <Field
                    type="email"
                    name="email"
                    className="floating-input radius-top"
                    placeholder=""
                  />
                  <label htmlFor="email" className="floating-label">
                    Adresse e-mail
                  </label>
                </div>
                

                <div className="form-group floating-input-container">
                  <Field
                    type="tel"
                    name="phone"
                    className="floating-input radius-bottom"
                    placeholder=""
                  />
                  <label htmlFor="phone" className="floating-label">
                    Numéro de téléphone
                  </label>
                </div>
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-danger"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
                <div className="form-group floating-input-container  mt-16">
                  <Field
                    as="textarea"
                    name="message"
                    className="floating-input"
                    placeholder=""
                    rows="4"
                  />
                  <label htmlFor="message" className="floating-label">
                    Votre message
                  </label>
                </div>
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-danger"
                />

                <div className="form-group mt-3">
                  <button
                    type="submit"
                    className="btn-primary-s w-100"
                    disabled={isSubmitting}
                  >
                    Envoyer
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Contact;
