import { useEffect, useState } from "react";
import NOImage from "../../assets/images/no-image.svg";
import Tears from "../../assets/images/tearsProfil.svg";
import Pen from "../../assets/images/pen.svg";
import ArrowRight from "../../assets/images/Arrow-right.svg";
import Completed from "../../assets/images/completed.svg";
import Services from "../../services/global.service";
import Header from "../../components/header";

const Profil = ({user}) => {
  const envUpload =process.env.REACT_APP_UPLOAD_URL
  const [image, setImage] = useState(null);
  const [completed, setCompleted] = useState(false);
  const handleImageChange = (e) => {
    console.log('here')
    const file = e.target.files[0];
    const formData=new FormData()
    formData.append('photo_profil',file)
    Services.create(formData,'update_user')
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
    
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Le résultat de la lecture du fichier
      };
      reader.readAsDataURL(file); // Lire l'image comme une URL de données
    } else {
      setImage(null); // Réinitialise si aucune image n'est sélectionnée
    }
  };
  useEffect(()=>{
    if(user?.photo_profil.length>0){
      //in backend delete {} empty 
      console.log(user?.photo_profil)
      setImage(user?.photo_profil)
    }
    else{
      setImage(null)
    }
    if(user?.description !=="" && user?.documents?.length>3 && user?.vehicule){
      setCompleted(true)
    }
  },[user])
  return (
    <>
  
    <div className=" bg-grey">
      <div className="container">

      <div className="image-upload pt-16">
        <div className=" position-relative icon-container-profil">
          <div className="image-preview  ">
            {image ? (
              <img src={image} alt="Aperçu" className="image-preview__image" />
            ) : (
              <img src={NOImage} alt="NOImage" className="w-auto" />
            )}
          </div>
          <img src={Tears} alt="Icon" className="floating-icon-profil" />
          <div className="circle-pen">
            <img
              src={Pen}
              alt="Edit Icon"
              className="edit-icon"
              onClick={() => document.getElementById("fileInput").click()} // Simule un clic sur l'input file
            />
          </div>
        </div>
        {/* Champ input de fichier caché */}
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }} // Masquer l'input file
        />
        <p className="exergue pt-10">{user?.nom}
          {completed && <img src={Completed} alt="profil completed" className=""/> }</p>
      </div>
     <a href="/aboutme" className="no-decoration" >
     <div className="card-atch">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
          A propos de nous
          <img src={ArrowRight} alt="Arrow right" className="" />
        </p>
        {!user?.description && <p className="current-text bleu400 m-0">Ajoutez des informations sur vous et vos préférences de voyage</p>}
      </div>
     </a>
     <a href="/myDocs" className="no-decoration" >
      <div className="card-atch mt-8">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
        Mes documents
          <img src={ArrowRight} alt="Arrow right" className="" />
        </p>
        {user?.documents.length < 3 && <p className="current-text bleu400 m-0">
          Ajoutez vos documents pour augmenter vos chances de trouver des passagers</p>}
      </div>
      </a>
      <a href="/ma-vehicule" className="no-decoration" >
      <div className="card-atch mt-8">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
           Mon véhicule
          <img src={ArrowRight} alt="Arrow right" className="" />
        </p>
        {!user?.vehicule && <p className="current-text bleu400 m-0">
          Ajoutez des informations sur votre véhicule pour aider les passagers à vous reconnaitre plus facilement </p>}
      </div>
      </a>
      <a href="/deplacement" className="no-decoration" style={{display:"none"}}>
      <div className="card-atch mt-8">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
           Mon périmètre de déplacement
          <img src={ArrowRight} alt="Arrow right" className="" />
        </p>
      </div>
      </a>
      <div className="solidaire mt-32 mb-80" style={{display:"none"}}>
        <p className="subtitle text-start">Vous souhaitez rendre service et recevoir les demandes de trajet sur votre territoire ? </p>
         <p className="current-text text-start">Vous serez conducteur solidaire et indemnisé à hauteur de 0.32c du km.
          </p> 
          <button className="btn-secondary-s p-16 w-100">Devenir conducteur solidaire</button>  
      </div>
      </div>
    </div>
    </>
  );
};
export default Profil;
