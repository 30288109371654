import { Form, Formik, ErrorMessage } from "formik";
import Header from "../../components/header";
import ReturnIcon from "../../assets/images/return.svg";
import Alert from "../../assets/images/danger.svg";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Range } from "react-range";

const Perimetre = () => {
  const navigate = useNavigate();
  const [adresse, setAdresse] = useState("");
  const [communes, setCommunes] = useState("");
  const [selectedDistance, setSelectedDistance] = useState([20]);
  const [LatLng, setLatLng] = useState(null);
  const [error, setError] = useState("");
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  // Load Google Maps script once on component mount
  useEffect(() => {
    if (!window.google) { // Only load if Google Maps is not already loaded
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true); // Set state when loaded
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true); // Google Maps is already loaded
    }
  }, []);

  // Effect to fetch and filter communes based on selected distance and LatLng
  useEffect(() => {
    if (!LatLng) return; // Avoid fetching until LatLng is set

    fetch("https://geo.api.gouv.fr/communes?fields=nom,centre&format=json")
      .then((response) => response.json())
      .then((data) => {
        const communes = data.map((commune) => ({
          name: commune.nom,
          lat: commune.centre.coordinates[1], // latitude
          lng: commune.centre.coordinates[0], // longitude
        }));
        const filteredCommunes = communes.filter((commune) => {
          const distance = calculateDistance(
            LatLng.lat,
            LatLng.lng,
            commune.lat,
            commune.lng
          );
          return distance <= selectedDistance;
        });
        console.log(filteredCommunes);
        setCommunes(filteredCommunes);
      })
      .catch((error) => console.error("Error fetching commune data:", error));
  }, [LatLng, selectedDistance]);

  // Distance calculation function
  function calculateDistance(lat1, lon1, lat2, lon2) {
    // console.log(lat1, lon1, lat2, lon2)
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      0.5 -
      Math.cos(dLat) / 2 +
      (Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        (1 - Math.cos(dLon))) /
        2;

    return R * 2 * Math.asin(Math.sqrt(a));
  }

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form data:", values);
    setSubmitting(false);
  };

  // Handle address change and selection
  const handleChangeAddress = (address, setFieldValue) => {
    setAdresse(address);
    setError("");
    setLatLng(null);
  };

  const handleSelect = async (address, setFieldValue) => {
    setAdresse(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatLng(latLng);
      setError("");
    } catch (error) {
      console.error("Error", error);
    }
  };

  // Autocomplete search options
  const searchOptions = {
    componentRestrictions: { country: ["fr"] },
    types: ["address"],
  };

  return (
    <>
      <div className="desktop">
        <Header connected={true} />
      </div>

      <div className="bg-atchoum-profil">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex">
            <button onClick={() => navigate(-1)} className="cta">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </button>
            Périmètre de déplacement
          </h1>
        </div>
        <div className="p-16 mt-32">
          <p className="subtitle bleuM500 text-start">
            Définissez votre périmètre de déplacement
          </p>
          <p className="current-text">
            Indiquez les communes dans lesquelles vous acceptez de vous
            déplacer. Les passagers verront votre profil si leurs trajets
            concernent votre périmètre.
          </p>

          <Formik initialValues={{ adresse: "" }} onSubmit={handleSubmit}>
            {({ setFieldValue }) => (
              <Form className="mt-24 ">
                <div className=" col-lg-4 mx-auto">
                  <p className="exergue">
                    <span className="number-round mr-8">1</span>Votre adresse de
                    domicile
                  </p>
                  {/* Adresse Field with Autocomplete */}
                  <div className="floating-input-container mt-8">
                  {isGMapsLoaded &&    <Autocomplete
                      value={adresse}
                      searchOptions={searchOptions}
                      onChange={(address) =>
                        handleChangeAddress(address, setFieldValue)
                      }
                      onSelect={(address) =>
                        handleSelect(address, setFieldValue)
                      }
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="floating-input-container ">
                          <input
                            {...getInputProps({
                              placeholder: "",
                              className: `floating-input ${
                                error ? "danger-input" : ""
                              }`,
                            })}
                            id="floatingAdr"
                            name="adresse"
                          />
                          <label
                            htmlFor="floatingAdr"
                            className="floating-label"
                          >
                            Adresse
                          </label>
                          <div
                            className={
                              suggestions.length > 0
                                ? "container-autocomplete"
                                : ""
                            }
                          >
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41B6E6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  className="mapItem"
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Autocomplete>
}
                    <ErrorMessage name="adresse">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className=" col-lg-4 mx-auto">
                  <p className="exergue">
                    <span className="number-round mr-8">2</span>Sélectionnez les
                    communes
                  </p>
                  <p className="current-text bleu400">
                    Vous recevrez des demandes de trajet solidaires depuis ces
                    communes.
                  </p>
                  {/* Range Slider for selecting distance */}
                  <div className="mt-32 card-communes">
                    <Range
                      step={1}
                      min={0}
                      max={100}
                      values={selectedDistance}
                      onChange={(values) => setSelectedDistance(values)}
                      renderTrack={({ props, children }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "6px",
                            width: "100%",
                            backgroundColor: "#0C836B",
                            position: "relative",
                          }}
                        >
                          {children}
                          <span
                            className="bubble"
                            style={{
                              position: "absolute",
                              top: "-58px", // Adjust to control the distance above the thumb
                              left: `calc(${
                                (selectedDistance / 100) * 100
                              }% - 30px)`, // Center the bubble above the thumb
                            }}
                          >
                            {selectedDistance} Km
                          </span>
                        </div>
                      )}
                      renderThumb={({ props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "24px",
                            width: "24px",
                            borderRadius: "50%",
                            backgroundColor: "#FFF", // White background for the thumb
                            borderTop: "6px solid #0C836B", // Custom top border (green)
                            borderBottom: "6px solid #0C836B", // Custom bottom border (green)
                            borderLeft: "6px solid #0C836B", // Remove left and right borders if not needed
                            borderRight: "6px solid #0C836B",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    {communes &&
                      communes.map((commune) => (
                        <div key={commune.lat}>{commune.name}</div>
                      ))}
                  </div>
                </div>

                <div className="bottom-white-block">
                  <button type="submit" className="btn-primary-s p-16 w-sm-100">
                    Enregistrer
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Perimetre;
