import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// Import assets
import "../../assets/styles/login.scss";
import ReturnIcon from "../../assets/images/return.svg";
import Alert from "../../assets/images/danger.svg";

import Header from '../../components/header';
import Services from '../../services/global.service';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const ResetPassword = () => {
const navigate=useNavigate()
  const initialValues = {
    email: '',
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
  });

 

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    console.log('Form data:', values);
    Services.create(values,'forgot-password')
    .then((res)=>{
      console.log(res)
      navigate('/sendCode',{state: {
        email: values.email,
      }})
    })
    .catch((err)=>{
      console.log(err)
      toast.error(err?.response?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'

      });
    })
    // Simulate async operation
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  return (
    <>
     <div className="desktop">
       <Header/> 
    </div>
    <div className="bg-atchoum ">
      <div className="container-fluid-lg">
         <h1 className="text-white pt-8 px-16 d-flex align-items-center">
         <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
        Mot de passe oublié
      </h1>
      <p className="exergue bleu3 px-16 pt-8 ">
        Confirmez votre e-mail et nous vous enverrons 
        un code pour réinitialiser votre mot de passe.
      </p>
      </div>
     

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched , isValid, dirty}) => (
          <Form className="mt-32">
            <div className="px-16 col-lg-4 mx-auto">
           
              {/* Email Field */}
              <div className="floating-input-container">
                <Field
                  type="email"
                  name="email"
                  className={`floating-input ${touched.email && errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder=" " // important for floating effect
                />
                <label htmlFor="email" className="floating-label">
                  Adresse e-mail
                </label>
              
              </div>

             
            </div>
            {/* <ErrorMessage name="email" component="div" className="invalid-feedback" /> */}
            <ErrorMessage name="email" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
            <div className="bottom-white-block">

              <button type="submit" disabled={!(isValid && dirty)} className="btn-primary-s p-16 w-sm-100">Suivant</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    </>
  );
};

export default ResetPassword;
