import Modal from 'react-bootstrap/Modal';
import IconClose from '../assets/images/iconClose.svg'

function ShowDocModal({open,setOpen,url}) {
  return (
    <Modal show={open} onHide={()=>{setOpen(false)}}>
      <Modal.Header className="border-none d-flex justify-content-between">
          <Modal.Title className=" ">
          
            </Modal.Title>
            <button className="cta" onClick={()=>setOpen(false)}><img src={IconClose} alt="icon close"/></button>
         
        </Modal.Header>
   
      <Modal.Body>
        <img src={url} alt="document image" className='w-100'/>
      </Modal.Body>
  </Modal>
  );
}

export default ShowDocModal;