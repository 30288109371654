import Logo from '../assets/images/logoWhite.svg'
import '../assets/styles/footer.scss'
import Social from "../assets/images/social.svg"
const Footer=()=>{
return(
    <footer className="">
        <img src={Logo} alt="logo atchoum white"/>
        <p className="copyright">Atchoum © {new Date().getFullYear()}</p>
        <div className="mt-24">
            <p className=' m-0 '><a className='link-footer  p-0'>Conditions générales d’utilisation</a></p>
            <p className=' mt-8 mb-0'> <a className='link-footer p-0'>Mentions légales</a></p>
            <p className=' mt-8 mb-0'><a className='link-footer  p-0'>Politique de confidentialité</a></p>
        </div>
        <div className="mt-24 contact">
            <p className="link-footer">contact@atchoum.eu</p>
            <p className="link-footer mt-16">04 42 24 42 24</p>
            <p className="link-footer-bold mt-16 ">Contact-nous</p>
        </div>
        <div className="mt-16">
            <img src={Social} alt="social icons" />
        </div>
    </footer>

)
}
export default Footer