import { Card, CardBody, CardImg } from "react-bootstrap";
import Image from "../assets/images/itemone.png";
import PlayIcon from "../assets/images/controls-play.svg"
const ItemBlog = ({ data, blog }) => {
  return (
    <Card className="card-atchoum p-8">
      <CardImg src={Image} alt="item img" />

      <CardBody className="p-0 text-start">
        <p className="textBlog bleuM500">{data?.text} </p>
        <span className="timing"><img src={PlayIcon} alt="icon play" className="mr-8"/>{data?.readingTime}</span>
      </CardBody>
    </Card>
  );
};
export default ItemBlog;
