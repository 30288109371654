import React, { useRef, useState } from "react";
// Import assets
import "../../assets/styles/login.scss";
import ReturnIcon from "../../assets/images/return.svg";
import Alert from "../../assets/images/danger.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Services from "../../services/global.service";

const StepTwoRegister = () => {
    const navigate=useNavigate()

  const [code, setCode] = useState('');
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),  
];
function handleInput(e, index) {
  const input = e.target;
  const previousInput = inputRefs[index - 1];
  const nextInput = inputRefs[index + 1];

  // Update code state with single digit
  const newCode = [...code];
  // Convert lowercase letters to uppercase
  if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      inputRefs[index].current.value = uc;
  } else {
      newCode[index] = input.value;
  }
  setCode(newCode.join(''));

  input.select();

  if (input.value === '') {
      // If the value is deleted, select previous input, if exists
      if (previousInput) {
          previousInput.current.focus();
      }
  } else if (nextInput) {
      // Select next input on entry, if exists
      nextInput.current.select();
  }
  console.log('code', code)
}
const handleVerify=()=>{
  if(code==localStorage.getItem('register_code')){
    window.location.href ='/last-step-register'
  }
  else{
    toast.error('Code incorrecte', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
     // theme: 'light', // Choose theme (light/dark)
      className: 'custom-toast-error'

    });
  }
}
// Select the contents on focus
function handleFocus(e) {
  e.target.select();
}
// Handle backspace key
function handleKeyDown(e, index) {
  const input = e.target;
  const previousInput = inputRefs[index - 1];
  const nextInput = inputRefs[index + 1];

  if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
      e.preventDefault();
      setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
      if (previousInput) {
          previousInput.current.focus();
      }
  }
}
const resendCode=()=>{
  Services.create({},'resend-registration-code')
  .then((res)=>{
    console.log(res)
    localStorage.setItem('register_code',res.data.user.register_code )
    toast.success('un code a été envoyé avec succès', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
     // theme: 'light', // Choose theme (light/dark)
      className: 'custom-toast mt-8'

    });
  })
  .catch((err)=>{
    console.log(err)
  })
}
// Capture pasted characters
const handlePaste = (e) => {
  const pastedCode = e.clipboardData.getData('text');
  if (pastedCode.length === 4) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
          inputRef.current.value = pastedCode.charAt(index);
      });
  }
};
  
  return (
    <div className="bg-grey">
      <div className="stepTwoBg ">
        <div className="container-fluid-lg p-16">
        <h1 className="text-white p-16 d-flex">
         <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
        Inscription
      </h1>
        </div>
    
      
      </div> 
      <div className="absolute-container">
      <h2 className="p-16 text-center">
         Entrez votre code reçu pas e-mail
        </h2>
        <div className="row d-flex justify-content-around gap-2 relative px-16 mt-32 col-lg-4 mx-auto">
          {[0, 1, 2, 3].map((index) => (
            <input
              className=" code-input text-2xl bg-gray-800 w-10 flex p-2 text-center"
              key={index}
              type="text"
              maxLength={1}
              onChange={(e) => handleInput(e, index)}
              ref={inputRefs[index]}
              autoFocus={index === 0}
              onFocus={handleFocus}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={handlePaste}
            />
          ))}
        </div>
      </div>
     
        <div className="bottom-white-block">
          <p className="current-text mb-8 text-center-lg">Vous n’avez pas reçu de code ?</p>
          <p className="text-start-sm text-center-lg m-0">
            <button onClick={resendCode} className="cta text-center-lg text-start">
             Renvoyer un code
              </button> 
          </p>
          
          <div className="mt-24">
            <button type="submit" disabled={code.length<4}className="btn-primary-s p-16 w-sm-100" onClick={handleVerify}>
              Valider
            </button>
          </div>
        </div>
    </div>
  );
};
export default StepTwoRegister;
