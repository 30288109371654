import { ErrorMessage, Formik } from "formik";
import ReturnIcon from "../../assets/images/return.svg";
import Alert from "../../assets/images/danger.svg";
import Header from "../../components/header";
import { Form, FormCheck } from "react-bootstrap";
import { useEffect, useState } from "react";
import './profil.scss';
import Services from "../../services/global.service";
import { toast } from "react-toastify";

const MyCar = () => {
  const [initialValues, setInitialValues] = useState({
    matricule: "",
    marque: "",
    modele: "",
    couleur: "",
    pmr: false,
    animaux: false,
  });

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedpmr, setIsCheckedPmr] = useState(false);

  const handleSwitchChange = (type) => {
    if (type === "pmr") {
      setIsCheckedPmr(!isCheckedpmr);
    } else {
      setIsChecked(!isChecked);
    }
  };

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form data:", values);
    const data = {
      immatriculation_vehicule: values.matricule,
      marque_vehicule: values.marque,
      modele_vehicule: values.modele,
      couleur_vehicule: values.couleur,
      accepte_pmr: isCheckedpmr,
      accepte_animaux: isChecked,
    };
    Services.create(data, 'update_user')
      .then((res) => {
        console.log(res);
        toast.success('Votre opération a réussi !', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast'
  
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false); // Set submitting to false when done
      });
  };

  const getMyProfil = async () => {
    const idUser = localStorage.getItem('userData');
    try {
      const res = await Services.getOne(idUser, 'users');
      const car = res?.data?.data?.vehicule;
      if (car) {
    
        setInitialValues({
          matricule: car?.immatriculation ,
          marque: car?.marque ,
          modele: car?.modele ,
          couleur: car?.couleur,
          pmr: car?.accepte_pmr ,
          animaux: car?.accepte_animaux ,
        });
        setIsChecked(car?.accepte_animaux );
        setIsCheckedPmr(car?.accepte_pmr );
        

      }
      
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMyProfil();
  }, []);

  return (
    <>
      <div className="desktop">
        <Header connected={true}/>
      </div>

      <div className="bg-atchoum-profil">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex align-items-center mb-100 ">
            <a href="javascript:history.go(-1)">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </a>
            Mon véhicule
          </h1>
        </div>
        <div className="mt-32 container-fluid-lg col-lg-4 mx-auto">
          <p className="subtitle px-16 pt-16 bleuM500 text-start mt-32">
            Vous pouvez ajouter des informations sur votre véhicule pour aider
            le passager à vous reconnaître plus facilement.
          </p>
          <Formik
            initialValues={initialValues}
            enableReinitialize // Allow reinitialization of form
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit} className="mt-24 mb-xs-100">
                <div className="px-16">
                  <div className="">
                    {/* matricule Field */}
                    <div className="floating-input-container">
                      <input
                        type="text"
                        name="matricule"
                        className={`floating-input ${touched.matricule && errors.matricule ? "is-invalid" : ""}`}
                        id="matricule"
                        placeholder=" "
                        value={values.matricule}
                        onChange={handleChange}
                      />
                      <label htmlFor="matricule" className="floating-label">
                        Plaque d’immatriculation
                      </label>
                    </div>
                    <ErrorMessage name="matricule" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                    {/* <ErrorMessage name="matricule" component="div" className="invalid-feedback" /> */}
                  </div>

                  <div className="col-lg-12 mx-auto mt-24">
                    {/* marque Field */}
                    <div className="floating-input-container">
                      <input
                        type="text"
                        name="marque"
                        className={`floating-input ${touched.marque && errors.marque ? "is-invalid" : ""}`}
                        id="marque"
                        placeholder=" "
                        value={values.marque}
                        onChange={handleChange}
                      />
                      <label htmlFor="marque" className="floating-label">
                        Marque du véhicule
                      </label>
                    </div>
                    <ErrorMessage name="marque" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                    {/* <ErrorMessage name="marque" component="div" className="invalid-feedback" /> */}
                  </div>

                  <div className="col-lg-12 mx-auto mt-24">
                    {/* modèle Field */}
                    <div className="floating-input-container">
                      <input
                        type="text"
                        name="modele"
                        className={`floating-input ${touched.modele && errors.modele ? "is-invalid" : ""}`}
                        id="modele"
                        placeholder=" "
                        value={values.modele}
                        onChange={handleChange}
                      />
                      <label htmlFor="modele" className="floating-label">
                        Modèle du véhicule
                      </label>
                    </div>
                    <ErrorMessage name="modele" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                    {/* <ErrorMessage name="modele" component="div" className="invalid-feedback" /> */}
                  </div>

                  <div className="col-lg-12 mx-auto mt-24">
                    {/* couleur Field */}
                    <div className="floating-input-container">
                      <input
                        type="text"
                        name="couleur"
                        className={`floating-input ${touched.couleur && errors.couleur ? "is-invalid" : ""}`}
                        id="couleur"
                        placeholder=" "
                        value={values.couleur}
                        onChange={handleChange}
                      />
                      <label htmlFor="couleur" className="floating-label">
                        Couleur du véhicule
                      </label>
                    </div>
                    <ErrorMessage name="couleur" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                    {/* <ErrorMessage name="couleur" component="div" className="invalid-feedback" /> */}
                  </div>
                </div>

                <div className="p-16">
                  <div className="col">
                    <div className="custom-switch custom-switch-label-io d-flex align-items-center">
                      <FormCheck
                        type="switch"
                        id="custom-switch-pmr"
                        className="form-switch mr-8"
                        checked={isCheckedpmr}
                        onChange={() => handleSwitchChange('pmr')}
                      />
                      <label className="bleu600 legend" htmlFor="custom-switch-pmr">
                        Mon véhicule est équipé pour accueillir une personne à mobilité réduite (PMR)
                      </label>
                    </div>
                  </div>
                </div>

                <div className="px-16">
                  <div className="col">
                    <div className="custom-switch custom-switch-label-io d-flex align-items-center">
                      <FormCheck
                        type="switch"
                        id="custom-switch"
                        className="form-switch mr-8"
                        checked={isChecked}
                        onChange={() => handleSwitchChange('animaux')}
                      />
                      <label className="bleu600 legend" htmlFor="custom-switch">
                        J’accepte les animaux de compagnie à bord
                      </label>
                    </div>
                  </div>
                </div>

                <div className="bottom-white-block">
                  <button type="submit" className="btn-primary-s p-16 w-sm-100">
                    Enregistrer
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default MyCar;
