import { Form, Formik, ErrorMessage } from 'formik';
import Header from '../../components/header';
import ReturnIcon from "../../assets/images/return.svg";

import './profil.scss'
import { useNavigate } from 'react-router-dom';

const Deplacement=()=>{
  const navigate=useNavigate()

    const initialValues = {
        presentation: '',
      };
      // Handle form submission
      const handleSubmit = (values, { setSubmitting }) => {
        console.log('Form data:', values);
        // Simulate async operation
        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
      };
    return(
        <>
        <div className="desktop">
           <Header connected={true}/> 
        </div>
      
        <div className="bg-atchoum-profil">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex">
             <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
            Périmètre de déplacement
          </h1>
        </div>
        <div className="p-16 mt-32">
        <p className="subtitle bleuM500 text-start">
        Définissez votre périmètre de déplacement
        </p>
        <p className="current-text">
        Indiquez les communes dans lesquelles vous acceptez de vous déplacer.
        Les passagers verront votre profil si leurs trajets concernent votre périmètre.
        </p>
        <p className="exergue">
        <span className="number-round mr-8">1</span>Votre adresse de domicile
        </p>
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="mt-24">
                
                <div className=" col-lg-4 mx-auto">
               
                  {/* Email Field */}
                  <div className="floating-input-container">
                    <input
                    type="text"
                      name="presentation"
                      className={`floating-input ${touched.presentation && errors.presentation ? 'is-invalid' : ''}`}
                      id="presentation"
                      placeholder=" " // important for floating effect
                    />
                    <label htmlFor="presentation" className="floating-label">
                    Votre présentation 
                    </label>
                  
                  </div>
    
                 
                </div>
                <ErrorMessage name="presentation" component="div" className="invalid-feedback" />
                <div className="bottom-white-block">
    
                  <button type="submit" className="btn-primary-s p-16 w-sm-100">Enregistrer</button>
                </div>
              </Form>
            )}
          </Formik>
          <p className="legend bleu400">Ce texte sera public sur votre profil, il pourra être modifié à tout moment</p>
        </div>
        </div>
     
    
        </>
    )
}
export default Deplacement;