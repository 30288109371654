import { Card, CardBody, CardImg } from "react-bootstrap";
import  Image from '../assets/images/itemone.png'
import  Quote from '../assets/images/close-quote.svg'
const ItemTestimonials=({data,key})=>{
return(
    <Card className="card-atchoum heigh350 p-16" >
        
    <CardBody className="p-0">
        <div className="row">
            <div className="col-auto mx-0 pr-0"><img src={Quote} alt=' close Quote icon' /></div>
            <div className="col pl-0">
                   <p className="textccm">{data?.avis}  </p>
        <p className="textccm">{data?.author}  <br/>{data?.function}  </p>
            </div>
        </div>
     
        
    </CardBody>
    </Card>
)
}
export default ItemTestimonials;