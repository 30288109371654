import { Modal, FormCheck } from "react-bootstrap";
import { useState } from "react";
import Calendar from "react-calendar";
import '../assets/styles/modalForm.scss';
import Closesvg from '../assets/images/close.svg';

import leftArrow from '../assets/images/arrow-up-left.png';
import rightArrow from '../assets/images/arrow-up-right.png';
import { Field } from "formik";


const ModalCalendar = ({ open, setOpen }) => {


  const [isChecked, setIsChecked] = useState(false); // Control switch state
  const [selectedDate, setSelectedDate] = useState(new Date()); // Calendar value
  const [isFlexible, setIsFlexible] = useState(false);


  const customShortWeekday = (locale, date) => {
    const days = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
    return days[date.getDay()];
  };


  const handleSwitchChange = () => {
    setIsChecked((prevState) => !prevState);
    setIsFlexible(!isFlexible)
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };


  const formatDisplayDate = (date) => {
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    return new Intl.DateTimeFormat('fr-FR', options).format(date);
  };

  const formatValueDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Ajouter un zéro devant les jours < 10
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois commence à 0, donc on ajoute 1
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const generateDates = () => {
    const today = new Date();
    let dates = [];

    for (let i = 0; i < 6; i++) {
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + i);
      dates.push({ display: formatDisplayDate(futureDate), value: formatValueDate(futureDate) });
    }

    return dates;
  };

  const dates = generateDates();


  return (
    <>


      <Modal
        show={open}
        onHide={() => {
          setOpen(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Date et heure de départ</h1>
          </Modal.Title>
          <button
            className="closeCta"
            onClick={() => setOpen(false)} // Close the modal when confirmed
          >
            <img src={Closesvg} />
          </button>
        </Modal.Header>

        <div className="p-16 popup">
          <div className="col">
            <div className="custom-switch custom-switch-label-io d-flex align-items-center">
              <FormCheck
                type="switch"
                id="custom-switch"
                className="form-switch mr-8"
                checked={isChecked}
                onChange={handleSwitchChange} // Correctly pass the function here
              />
              <label className="legend" htmlFor="custom-switch">
                Mes disponibilités sont flexibles
              </label>
            </div>
          </div>
        </div>
        <div style={{
          display: isFlexible ? 'none' : 'block'
        }}>
          <Modal.Body>
            <Calendar onChange={handleDateChange} value={selectedDate} formatShortWeekday={customShortWeekday}
              prevLabel={<img src={leftArrow} alt="Previous" className="arrow" />}
              nextLabel={<img src={rightArrow} alt="Next" className="arrow" />}
            />
            <p className="time">Partir à <input type="time" name="heure-dept" className="w-auto form-control" /></p>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn-primary-s w-100"
              onClick={() => setOpen(false)} // Close the modal when confirmed
            >
              Confirmer la date et l'heure
            </button>
          </Modal.Footer>
        </div>
        <div style={{
          display: !isFlexible ? 'none' : 'block'
        }}>
          <Modal.Body>
            <h2>Quand voulez vous partir ?</h2>
            <label>Quel jour ?</label>
            {dates.map((date, index) => (
              <div className="form-group text-start mt-16" key={index}>
                <label className="d-flex align-items-center current-text">
                  <input
                    type="checkbox"
                    name="conducteurSolidaire"
                    id={`day-${index}`}
                    value={date.value} 
                  />
                  <span className="custom-checkbox"></span> {date.display}
                </label>
              </div>
            ))}

            <label>Période de la journée</label>
            <div className="form-group text-start mt-16" >
                <label className="d-flex align-items-center current-text">
                  <input
                    type="checkbox"
                    name="conducteurSolidaire"
                    id={`matin`}
                    value="Matin"
                  />
                  <span className="custom-checkbox"></span> Matin
                </label>
              </div>
              <div className="form-group text-start mt-16" >
                <label className="d-flex align-items-center current-text">
                  <input
                    type="checkbox"
                    name="conducteurSolidaire"
                    id={`apres-midi`}
                    value="Après-midi"
                  />
                  <span className="custom-checkbox"></span> Après-midi
                </label>
              </div>
              <div className="form-group text-start mt-16" >
                <label className="d-flex align-items-center current-text">
                  <input
                    type="checkbox"
                    name="conducteurSolidaire"
                    id={`soir`}
                    value="Soir"
                  />
                  <span className="custom-checkbox"></span> Soir
                </label>
              </div>

          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn-primary-s w-100"
              onClick={() => setOpen(false)} // Close the modal when confirmed
            >
              Confirmer la période
            </button>
          </Modal.Footer>
        </div>
      </Modal>

    </>
  );
};

export default ModalCalendar;
