
import './App.scss';
import '../src/assets/styles/ui.scss'
import '../src/assets/styles/colors.scss'
import '../src/assets/styles/btn.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import views
import Home from './views/home';
import Login from './views/auth/login';
import ResetPassword from './views/auth/resetPassword';
import SendCode from './views/auth/sendCode';
import NewPassword from './views/auth/nvPassword';
import StepOneRegister from './views/register/stepOneRegistre';
import MyPorfile from './views/profile/myProfile';
import StepTwoRegister from './views/register/secondStepRegister';
import Register from './views/register/lastStepRegister';
import AboutMe from './views/profile/aboutMe';
import MyDocs from './views/profile/myDocs';
import MyCar from './views/profile/myCar';
import Deplacement from './views/profile/deplacement';
import Informations from './views/profile/informations';
import ChangePassword from './views/profile/changePassword';
import Notifs from './views/profile/notifs';
import BankAccount from './views/profile/bankAccount';
import ProtectedRoutes from './utils/ProtectedRoutes';
import MyPaiements from './views/profile/myPaiements';
import Bulletin from './views/solidaire/bulletin';
import Apropos from './views/solidaire/aporpos';
import Photo from './views/solidaire/photo';
import Car from './views/solidaire/car';
import Perimetre from './views/solidaire/perimetre';
import { useEffect } from 'react';

function App() {

  
  
  return (
    <div className="App">
        <ToastContainer />
      <BrowserRouter>
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/sendCode" element={<SendCode />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/register" element={<StepOneRegister />} />
          <Route path="/verify-code" element={<StepTwoRegister />} />
          <Route path="/last-step-register" element={<Register />} />
          <Route element={<ProtectedRoutes role="" />}> 
            <Route path="/mon-profil" element={<MyPorfile />} />
            <Route path="/aboutme" element={<AboutMe />} />
            <Route path="/myDocs" element={<MyDocs />} />
            <Route path="/ma-vehicule" element={<MyCar />} />
            <Route path="/deplacement" element={<Deplacement />} />
            <Route path="/informations" element={<Informations />} />
            <Route path="/changement-motdepasse" element={<ChangePassword />} />
            <Route path="/notifs" element={<Notifs />} />
            <Route path="/paiements" element={<MyPaiements />} />
            <Route path="/compte-bancaires" element={<BankAccount />} />
            <Route path="/bulletin-adhesion" element={<Bulletin />} />
            <Route path="/a-propos-conducteur-solidaire" element={<Apropos />} />
            <Route path="/photo" element={<Photo />} />
            <Route path="/vehicule-solidaire" element={<Car />} />
            <Route path="/perimetre-solidaire" element={<Perimetre />} />
          </Route>
         
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
