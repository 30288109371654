import { ErrorMessage, Formik } from "formik";
import ReturnIcon from "../../assets/images/return.svg";
import Header from "../../components/header";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BankAccount = () => {
  const navigate=useNavigate()
  const initialValues = {
    titulaire: "",
    iban: "",
    bic: "",
  };
  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form data:", values);
    // Simulate async operation
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };
  return (
    <>
      <div className="desktop">
        <Header connected={true} />
      </div>

      <div className="bg-atchoum-profil">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex align-items-center">
             <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
            Mes coordonnées bancaires
          </h1>
        </div>
        <div className=" mt-24">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ errors, touched }) => (
              <Form className="">
                <div className="px-16">

               
                <div className=" col-lg-4 mx-auto">
                  {/* titulaire Field */}
                  <div className="floating-input-container">
                    <input
                      type="text"
                      name="titulaire"
                      className={`floating-input ${
                        touched.titulaire && errors.titulaire
                          ? "is-invalid"
                          : ""
                      }`}
                      id="titulaire"
                      placeholder=" " // important for floating effect
                    />
                    <label htmlFor="titulaire" className="floating-label">
                      Titulaire du compte
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="titulaire"
                  component="div"
                  className="invalid-feedback"
                />
                 <div className=" col-lg-4 mx-auto mt-8">
                  {/* iban Field */}
                  <div className="floating-input-container">
                    <input
                      type="text"
                      name="iban"
                      className={`floating-input ${
                        touched.iban && errors.iban
                          ? "is-invalid"
                          : ""
                      }`}
                      id="iban"
                      placeholder=" " // important for floating effect
                    />
                    <label htmlFor="iban" className="floating-label">
                      IBAN
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="iban"
                  component="div"
                  className="invalid-feedback"
                />
                   <div className=" col-lg-4 mx-auto mt-8">
                  {/* BIC Field */}
                  <div className="floating-input-container">
                    <input
                      type="text"
                      name="bic"
                      className={`floating-input ${
                        touched.bic && errors.bic
                          ? "is-invalid"
                          : ""
                      }`}
                      id="bic"
                      placeholder=" " // important for floating effect
                    />
                    <label htmlFor="bic" className="floating-label">
                      BIC
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="iban"
                  component="div"
                  className="invalid-feedback"
                />
                 </div>
                <div className="bottom-white-block">
                  <button type="submit" className="btn-primary-s p-16 w-sm-100">
                    Enregistrer
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default BankAccount;
