import React from 'react';

// Import des icônes
import HomeIconActive from '../assets/images/house-active.svg';
import HomeIcon from '../assets/images/home.svg';
import TrajetsIconActive from '../assets/images/trip-active.svg';
import TrajetsIcon from '../assets/images/trip-destination.svg';
import AmisIconActive from '../assets/images/users-actives.svg';
import AmisIcon from '../assets/images/users.svg';
import MessagesIconActive from '../assets/images/messages-bubble-typing-active.svg';
import MessagesIcon from '../assets/images/messages.svg';
import ProfilIconActive from '../assets/images/profil-active.svg';
import ProfilIcon from '../assets/images/profil.svg';
import { useLocation } from 'react-router-dom';

const TopBar = ({header}) => {
  const location = useLocation(); // Utilisation de useLocation pour obtenir l'URL actuelle

  // Fonction pour ajouter la classe active en fonction de l'URL
  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  // Fonction pour obtenir l'icône en fonction de l'URL
  const getIcon = (path, icon, iconActive) => {
    return location.pathname === path ? iconActive : icon;
  };

  return (
  <>
 
    <div className={`${header ? 'top-bar-desktop w-50 mx-auto': 'top-bar' }`}>
      <a href="/" className={`menu-item ${getActiveClass('/')}`}>
        <img src={getIcon('/', HomeIcon, HomeIconActive)} alt="Accueil" className="menu-icon" />
        <span>Accueil</span>
      </a>
      <a href="/mes-trajets" className={`menu-item ${getActiveClass('/mes-trajets')}`}>
        <img src={getIcon('/mes-trajets', TrajetsIcon, TrajetsIconActive)} alt="Mes trajets" className="menu-icon" />
        <span>Mes trajets</span>
      </a>
      <a href="/mes-amis" className={`menu-item ${getActiveClass('/mes-amis')}`}>
        <img src={getIcon('/mes-amis', AmisIcon, AmisIconActive)} alt="Mes amis" className="menu-icon" />
        <span>Mes amis</span>
      </a>
      <a href="/mes-messages" className={`menu-item ${getActiveClass('/mes-messages')}`}>
        <img src={getIcon('/mes-messages', MessagesIcon, MessagesIconActive)} alt="Messages" className="menu-icon" />
        <span>Messages</span>
      </a>
      <a href="/mon-profil" className={`menu-item ${getActiveClass('/mon-profil')}`}>
        <img src={getIcon('/mon-profil', ProfilIcon, ProfilIconActive)} alt="Profil" className="menu-icon" />
        <span>Profil</span>
      </a>
    </div>
    </>
  );
};

export default TopBar;
