import Modal from 'react-bootstrap/Modal';
import IconClose from '../assets/images/iconClose.svg'

function ModalAtchoum({open,setOpen,text}) {
  return (
    <Modal show={open} onHide={()=>{setOpen(false)}}>
      <Modal.Header className="border-none d-flex justify-content-between">
          <Modal.Title className=" ">
            <h1 className="bleu600">{text}</h1> 
            </Modal.Title>
            <button className="cta"><img src={IconClose} alt="icon close"/></button>
         
        </Modal.Header>
   
    <Modal.Footer>
      <button className="btn-primary-s w-100" >
        Je confirme
      </button>
      <button className='btn-secondary-s w-100' >
        Annuler
      </button>
    </Modal.Footer>
  </Modal>
  );
}

export default ModalAtchoum;