import React, { useRef, useState } from "react";
// Import assets
import "../../assets/styles/login.scss";
import ReturnIcon from "../../assets/images/return.svg";
import Header from "../../components/header";
import Services from "../../services/global.service";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const SendCode = () => {
  const location = useLocation();
  let email = location.state.email;
  const navigate=useNavigate()
  const [code, setCode] = useState("");
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  function handleInput(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    // Update code state with single digit
    const newCode = [...code];
    // Convert lowercase letters to uppercase
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      inputRefs[index].current.value = uc;
    } else {
      newCode[index] = input.value;
    }
    setCode(newCode.join(""));

    input.select();

    if (input.value === "") {
      // If the value is deleted, select previous input, if exists
      if (previousInput) {
        previousInput.current.focus();
      }
    } else if (nextInput) {
      // Select next input on entry, if exists
      nextInput.current.select();
    }
    console.log("code", code);
  }
  // Select the contents on focus
  function handleFocus(e) {
    e.target.select();
  }
  // Handle backspace key
  function handleKeyDown(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === "") {
      e.preventDefault();
      setCode(
        (prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1)
      );
      if (previousInput) {
        previousInput.current.focus();
      }
    }
  }
  // Capture pasted characters
  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData("text");
    if (pastedCode.length === 6) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index);
      });
    }
  };

  // resend code
  const ResendCode=()=>{
    Services.create({email:email},'forgot-password')
    .then((res)=>{
      console.log(res)
      
    })
  }

  // Handle form submission
  const handleSubmit = () => {
    // console.log("Form data:", values);
    // Simulate async operation
    Services.create({password_code:code,email:email},'check-code')
    .then((res)=>{
      console.log('res verify code',res)
      if(res.data.status==="success"){
        navigate('/new-password',{state: {
          email: email,
          token:res.data.token,
          code:code
        }})
      }
    })
    .catch((err)=>{
      console.log('err',err)
      toast.error(err?.response?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'

      });
    })
    
  };

  return (
    <>
      <div className="desktop">
        <Header />
      </div>
      <div className="bg-atchoum">
        <div className="container-fluid-lg">
          <h1 className="text-white pt-8 px-16 d-flex">
             <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
            Mot de passe oublié
          </h1>
          <p className="exergue bleu3 px-16 pt-8 ">
            Le code permettant de réinitialiser votre mot de passe a été envoyé
            à {email && email}. Si vous ne le voyez pas, vérifiez dans vos spams.
          </p>
        </div>
        <div className="row d-flex justify-content-around gap-2 relative px-16 mt-32 col-lg-4 mx-auto">
          {[0, 1, 2, 3].map((index) => (
            <input
              className=" code-input text-2xl bg-gray-800 w-10 flex p-2 text-center"
              key={index}
              type="text"
              maxLength={1}
              onChange={(e) => handleInput(e, index)}
              ref={inputRefs[index]}
              autoFocus={index === 0}
              onFocus={handleFocus}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={handlePaste}
            />
          ))}
        </div>
        <div className="bottom-white-block">
          <p className="current-text text-center mb-8">Vous n’avez pas reçu de code ?</p>
          <button onClick={ResendCode} className="cta">
            Renvoyer un code
          </button>
          <div className="mt-24">
            <button type="submit" onClick={handleSubmit} className="btn-primary-s p-16 w-sm-100">
              Valider
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendCode;
