import { useNavigate } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import CheckFill from "../../assets/images/checkfill.svg";
import "../../assets/styles/bulletin.scss";
import { Field } from "formik";
const Bulletin = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-grey">
        <div className="stepOne ">
          <div className="container-fluid-lg p-16">
            <h1 className="text-white  d-flex">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Devenir conducteur solidaire
            </h1>
          </div>
        </div>
        <h2 className="p-16 m-0 bleuM500 container-fluid-lg ">
          Bulletin d’adhésion à l’association
        </h2>
        <div className="px-16">
          <div className="solidaire ">
            
            <p className="current-text bleuM500 text-start">
            Votre commune relève de Transport d’Utilité Sociale. Si vous souhaitez proposer un trajet, l’adhésion à notre association Atchoum mobilité villages est alors obligatoire.
            </p>
          </div>
          <div className="why mt-24">
            <p className="exergue bleuM500">
                Pourquoi adhérer à l'association ?
            </p>
            <div className="mt-16">
                <p className="current-text p-0 m-0 bleuM500 align-items-center d-flex">
                 <img src={CheckFill} alt="check icon" className="mr-8"/> Adhésion gratuite
                </p>
                <p className="current-text p-0 mb-0 bleuM500 align-items-start d-flex mt-8">
                 <img src={CheckFill} alt="check icon" className="mr-8"/> En adhérant vous 
                 êtes couvert par l'assurance de l'association moyennant une cotisation 
                 de 5€/an, vous serez couvert tout risque pour 
                 tous les trajets enregistrés et réalisés avec Atchoum
                </p>
                <p className="current-text p-0 mb-0  bleuM500 align-items-start d-flex mt-8">
                 <img src={CheckFill} alt="check icon" className="mr-8"/> 
                 Pour créer une communauté entre les utilisateurs et recevoir des invitations à des évènements ...
                </p>
                <div className="form-group text-start  mt-8">
                  <label className="d-flex align-items-center current-text">
                    <input
                      type="checkbox"
                      name="accepteCguCgv"
                      id="accepteCguCgv"
                    />
                    <span className="custom-checkbox"></span>
                    <span> J’adhère à l’association 
                    </span>
                  </label>

                </div>
                
            </div>
            <div className="bottom-white-block">
                <button type="submit" 
               
                className="btn-primary-s p-16 w-sm-100">
                  Suivant
                </button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Bulletin;
