import { ErrorMessage, Formik } from "formik";
import ReturnIcon from "../../assets/images/return.svg";
import Header from "../../components/header";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MyPaiements = () => {
  const [image, setImage] = useState(null);
  const navigate=useNavigate()
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Le résultat de la lecture du fichier
      };
      reader.readAsDataURL(file); // Lire l'image comme une URL de données
    } else {
      setImage(null); // Réinitialise si aucune image n'est sélectionnée
    }
  };
  const mesDocs=[
    {name:"justif",file:"file.png", date:"13/09/2024"},
    {name:"assurance",file:"file.png", date:"13/09/2024"}
  ]
  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form data:", values);
    // Simulate async operation
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };
    // Fonction pour vérifier si un document existe
    const getDoc = (docName) => mesDocs.find((doc) => doc.name === docName);
  return (
    <>
      <div className="desktop">
        <Header connected={true}/>
      </div>

      <div className="bg-atchoum-profil">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex">
             <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
            Mes paiements
          </h1>
        </div>
        <div className="p-16 mt-32">
         <p className="subtitle">Juin 2024</p>
          <div className="card-atch mt-16">
           <div className="d-flex justify-content-between">
            <div>
            <p className="exergue my-0">23/06/24</p>
            <p className="exergue mt-8">Paris Montpellier</p>
            </div>
            <div><p className="exergue">-15,00€</p></div>
           </div>
            
          </div>
         
        </div>
        
      </div>
    </>
  );
};
export default MyPaiements;
